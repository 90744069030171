import React from "react"

import { Container, Grid } from "semantic-ui-react"
import "semantic-ui-css/semantic.min.css"

import ErrorBoundary from "../components/ErrorBoundary"
import MainMenu from "../components/MainMenu"

export const Layout: React.FC = props => (
  <Container style={{ marginTop: "3em" }}>
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <MainMenu />
        </Grid.Column>
      </Grid.Row>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </Grid>
  </Container>
)
