import React, { useContext } from "react"
import { Modal, Button, Form as SemanticForm, Message } from "semantic-ui-react"
import { Formik, Field, FormikProps, FormikBag } from "formik"

import { UserContext } from "../Contexts/UserContext"
import useWrappedAxios from "../hooks/useWrappedAxios"

interface FormData {
  email: string
  password: string
}

export default () => {
  const { user, login } = useContext(UserContext)

  const [, submitLogin] = useWrappedAxios(
    {
      url: `https://fams.fitogram.pro/auth`,
      method: "POST",
    },
    { manual: true }
  )

  const onSubmit = (values, actions: FormikBag<{}, FormData>) => {
    const data = {
      auth: {
        email: values.email,
        password: values.password,
        // eslint-disable-next-line @typescript-eslint/camelcase
        grant_type: "password",
      },
    }

    return submitLogin({ data })
      .then(response => {
        login(response.data)
      })
      .catch(e => {
        actions.setFieldError("password", e.response.data.message)
        actions.setSubmitting(false)
      })
  }

  if (user) {
    return null
  }

  return (
    <Modal trigger={<Button size="tiny">Connexion</Button>} closeIcon>
      <Modal.Header>Entrez vos infos de connexion Fitogram</Modal.Header>
      <Formik initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
        {({
          isSubmitting,
          handleReset,
          submitForm,
          errors,
        }: FormikProps<FormData>) => {
          return (
            <>
              <Modal.Content>
                <SemanticForm
                  noValidate
                  onReset={handleReset}
                  onSubmit={submitForm}
                  error={!!errors.password}
                >
                  <SemanticForm.Field>
                    <label htmlFor="email">Email</label>
                    <Field type="email" name="email" id="email" />
                  </SemanticForm.Field>
                  <SemanticForm.Field>
                    <label htmlFor="password">Mot de passe</label>
                    <Field type="password" name="password" id="password" />
                  </SemanticForm.Field>
                  {errors.password && (
                    <Message error content={errors.password} />
                  )}
                  <Button type="submit" disabled={isSubmitting}>
                    Se connecter
                  </Button>
                </SemanticForm>
              </Modal.Content>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}
