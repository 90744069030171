import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Menu, Image } from "semantic-ui-react"
import { UserContext } from "../Contexts/UserContext"
import ErrorBoundary from "./ErrorBoundary"
import UserProfile from "./UserProfile"

const query = graphql`
  query {
    file(relativePath: { eq: "baps-icon.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 42, height: 42) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default function MainMenu(props) {
  return (
    <StaticQuery query={query}>
      {data => (
        <Menu stackable borderless>
          <Menu.Item header>
            <Image as={Img} avatar fixed={data?.file?.childImageSharp?.fixed} />{" "}
          </Menu.Item>
          <Menu.Item as={Link} to="/" activeClassName="active" color="blue">
            Liste des Cours
          </Menu.Item>
          <UserContext.Consumer>
            {({ user }) => (
              <>
                {user && (
                  <Menu.Item
                    as={Link}
                    to="/myBookings"
                    activeClassName="active"
                    color="blue"
                  >
                    Mes réservations
                  </Menu.Item>
                )}
              </>
            )}
          </UserContext.Consumer>
          <Menu.Item position="right">
            <ErrorBoundary>
              <UserProfile />
            </ErrorBoundary>
          </Menu.Item>
        </Menu>
      )}
    </StaticQuery>
  )
}
