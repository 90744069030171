import React, { useContext } from "react"
import { UserContext } from "../Contexts/UserContext"
import LoginForm from "./LoginForm"
import useWrappedAxios from "../hooks/useWrappedAxios"
import { Image, Button, Popup, Icon } from "semantic-ui-react"

const baseURL = `https://fams.fitogram.pro/`

export default () => {
  const { user, logout } = useContext(UserContext)
  const [{ data, loading }] = useWrappedAxios({
    baseURL,
    url: `/users/myaccount`,
  })

  if (!user) {
    return <LoginForm />
  }

  if (loading) return <p>Loading…</p>

  if (!data) return null

  return (
    <p>
      {data.user.image_url && <Image avatar src={data.user.image_url} />}
      {data.user.first_name} {data.user.last_name}
      <Popup
        trigger={<Icon name="sign-out" style={{ marginLeft: ".5em" }} />}
        content={
          <Button onClick={logout} negative size="small">
            Déconexion
          </Button>
        }
        hoverable={true}
      />
    </p>
  )
}
